<template>
  <div class="row">
    <div class="col-12">
      <ibox
        :title="'Nagrody i certyfikaty'"
        :description="
          `Lista nagród i certyfikatów ${this.$route.params.shop_name} ${this.$route.params.shop_lang}`
        "
      >
        <template slot="tools">
          <router-link
            :to="
              `/${$route.params.shop_lang}/${$route.params.shop_name}/awards-and-certificates/add`
            "
            class="btn btn-primary btn-xs"
          >
            Dodaj nagrodę lub certyfikat
            <i class="fas fa-plus"></i>
          </router-link>
        </template>
        <template slot="content" slot-scope="{ setLoading }">
          <DataTable
            v-if="show"
            :manualColumns="manualColumns"
            :url="url"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "BestsellerSaleStatistics",
  components: {
    Ibox,
    DataTable
  },
  data() {
    return {
      show: true,
      manualColumns: [
        {
          key: "id",
          name: "ID",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "title",
          name: "Tytuł",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "copy",
          name: "Opis",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "award",
          name: "Nagroda",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "certificate",
          name: "Certyfikat",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "product_url",
          name: "URL produktu",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "file",
          name: "Plik",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "file_en",
          name: "Plik EN",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "file_de",
          name: "Plik DE",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "file_ru",
          name: "Plik RU",
          show: true,
          type: "",
          filter: ""
        },
        {
          key: "certificate_img",
          name: "Obrazek",
          show: true,
          type: "",
          filter: ""
        }
      ]
    };
  },
  computed: {
    url() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/awards-and-certificates/list`;
    }
  }
};
</script>
